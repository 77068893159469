
import initUI from '@/components/Common'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

export default {
  name: 'StyleButton',
  setup (props: any, context: any) {
    const info = initUI()
    const { t } = useI18n()
    const text = computed({
      get () {
        return info.getMeta('value') || ''
      },
      set (v) {
        info.setMeta('value', v)
      }
    })
    return {
      ...info,
      text,
      t
    }
  }
}
