
import initUI from '@/components/Common'
import { useI18n } from 'vue-i18n'
import Upload from '@/components/common/Upload.vue'
import { computed } from 'vue'

export default {
  name: 'StyleImage',
  components: { Upload },
  setup (props: any, context: any) {
    const info = initUI()
    // const selectedUIItem = info.selectedUIItem
    const { t } = useI18n()
    const image = computed({
      get () {
        const files = info.getMeta('value', 'files') || []
        return { url: info.getMeta('value'), id: files[0]?.id, name: files[0]?.name }
      },
      set (v: any) {
        // console.log(v)
        info.setMeta('value', v.url)
        info.setMeta('value', [{ id: v.id, name: v.name }], 'files')
      }
    })

    return {
      ...info,
      image,
      t
    }
  }
}
