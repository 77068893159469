
import initUI from '@/components/Common'
import { useI18n } from 'vue-i18n'
import StyleValueList from '@/components/sidebar/style/ValueList.vue'

export default {
  name: 'StyleNavbar',
  components: { StyleValueList },
  setup (props: any, context: any) {
    const info = initUI()
    const { t } = useI18n()
    return {
      ...info,
      t
    }
  }
}
