
import StyleFormValueList from '@/components/sidebar/style/ValueList.vue'
import { computed } from 'vue'
import initUI from '@/components/Common'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
  name: 'StyleCheckbox',
  components: { StyleFormValueList },
  setup (props: any, context: any) {
    const info = initUI()
    const store = useStore()
    const inline = computed({
      get () {
        return info.getMeta('inline', 'custom') || false
      },
      set (v) {
        info.setMeta('inline', v, 'custom')
      }
    })
    const { t } = useI18n()
    const isMobile = computed(() => store.state.design.endKind === 'mobile')
    return {
      t,
      inline,
      isMobile
    }
  }
}
