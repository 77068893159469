
import initUI from '@/components/Common'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import IconSetting from '@/components/sidebar/style/IconSetting.vue'

export default {
  name: 'StyleButton',
  components: { IconSetting },
  setup (props: any, context: any) {
    const store = useStore()
    const info = initUI()
    const { t } = useI18n()

    const buttonTypes = ref(['button', 'link'])
    const currType = computed({
      get () {
        return info.getMeta('type', 'custom') || 'button'
      },
      set (v) {
        info.setMeta('type', v, 'custom')
      }
    })
    const isOutline = computed({
      get () {
        return info.getMeta('isOutline', 'custom') || false
      },
      set (v) {
        info.setMeta('isOutline', v, 'custom')
      }
    })
    const sizing = computed({
      get: () => {
        return info.getMeta('buttonSizing', 'css') || ''
      },
      set: (v) => {
        info.setMeta('buttonSizing', v, 'css')
      }
    })
    const linkHref = computed({
      get () {
        return info.getMeta('linkHref', 'custom') || ''
      },
      set (v) {
        info.setMeta('linkHref', v, 'custom')
      }
    })
    const title = computed({
      get () {
        const title = info.getMeta('title')
        // console.log(title)
        return title !== undefined ? title : 'Button'
      },
      set (v) {
        // console.log(v)
        info.setMeta('title', v || ' ')
      }
    })

    const parentIsButtonGroup = computed(() => {
      const { parentConfig } = store.getters.getUIItemInPage(info.selectedUIItemId.value, info.selectedPageId.value)
      // console.log(parentConfig.type)
      return parentConfig.type.toLowerCase() === 'buttongroup'
    })
    const isButtonGroup = computed(() => {
      return info.selectedUIItem.value?.type?.toLowerCase() === 'buttongroup'
    })
    return {
      ...info,
      t,
      buttonTypes,
      currType,
      isOutline,
      linkHref,
      title,
      sizing,
      parentIsButtonGroup,
      isButtonGroup
    }
  }
}
