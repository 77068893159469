
import initUI from '@/components/Common'
import { useI18n } from 'vue-i18n'
import StyleValueList from '@/components/sidebar/style/ValueList.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'StyleNav',
  components: { StyleValueList },
  setup (props: any, context: any) {
    const info = initUI()
    const { t } = useI18n()
    const store = useStore()
    const type = computed({
      get: () => {
        return info.getMeta('type', 'custom') || ''
      },
      set: (v) => {
        info.setMeta('type', v, 'custom')
      }
    })
    const justified = computed({
      get: () => {
        return info.getMeta('justified', 'custom') || ''
      },
      set: (v) => {
        info.setMeta('justified', v, 'custom')
      }
    })
    const fill = computed({
      get: () => {
        return info.getMeta('filled', 'custom') || ''
      },
      set: (v) => {
        info.setMeta('filled', v, 'custom')
      }
    })
    const isMobile = computed(() => store.state.design.endKind === 'mobile')
    return {
      ...info,
      t,
      type,
      justified,
      isMobile,
      fill
    }
  }
}
