
import { useI18n } from 'vue-i18n'
import { computed, nextTick, ref, reactive, watch, toRaw } from 'vue'
import UIInit from '@/components/Common'

export default {
  name: 'StyleValueList',
  props: {
    valueIsRequired: Boolean
  },
  setup (props: any, context: any) {
    const initInfo = UIInit()
    const { t } = useI18n()
    const editValueIndex = ref(-1)
    const valueInvalid = ref(false)
    const editValue = computed(() => {
      if (editValueIndex.value <= -1) return { text: '', value: '', checked: false, disabled: false }
      return valueItems.value[editValueIndex.value]
    })
    const newItem = reactive({ text: '', value: '', checked: false, disabled: false })
    watch(editValue, (newValue) => {
      newItem.text = newValue ? newValue.text : ''
      newItem.value = newValue ? newValue.value : ''
      newItem.checked = newValue ? newValue.checked : false
      newItem.disabled = newValue ? newValue.disabled : false
    })

    const valueItems = computed(() => {
      return initInfo.getMeta('values') || []
    })

    const remove = (index) => {
      const values = JSON.parse(JSON.stringify(valueItems.value))
      values.splice(index, 1)
      initInfo.setMeta('values', values)
    }

    const updateChecked = (index) => {
      const values = JSON.parse(JSON.stringify(valueItems.value))
      if (isMultiple.value) {
        values[index].checked = !values[index].checked
      } else {
        // 单选的话把其他的反过来
        for (const valueIndex in values) {
          values[valueIndex].checked = false
        }
        values[index].checked = true
      }
      initInfo.setMeta('values', values)
    }

    const edit = (index) => {
      openSetting(index)
    }

    const isOpenSetting = ref(false)
    const rightBackdropVisible = ref(false)
    const openSetting = (editItemIndex) => {
      editValueIndex.value = editItemIndex
      valueInvalid.value = false
      newItem.value = ''
      newItem.text = ''
      newItem.checked = false
      newItem.disabled = false
      isOpenSetting.value = true
      nextTick(() => {
        rightBackdropVisible.value = true
      })
    }
    const closeSetting = () => {
      isOpenSetting.value = false
      rightBackdropVisible.value = false
      editValueIndex.value = -1
    }
    const isMultiple = computed(() => {
      return initInfo.getMeta('multiple', 'custom') || initInfo.selectedUIItem.value.type.toLowerCase() === 'checkbox' || false
    })
    const updateValue = () => {
      const rawItem = JSON.parse(JSON.stringify(toRaw(newItem)))
      if (!rawItem.value && props.valueIsRequired) {
        valueInvalid.value = true
        return
      }
      if (editValueIndex.value > -1) {
        const values = JSON.parse(JSON.stringify(valueItems.value))
        values[editValueIndex.value] = rawItem
        initInfo.setMeta('values', values)
      } else {
        initInfo.setMeta('values', [rawItem], '', true)
      }

      closeSetting()
    }

    return {
      t,
      rightBackdropVisible,
      isOpenSetting,
      editValueIndex,
      openSetting,
      closeSetting,
      isMultiple,
      updateValue,
      valueItems,
      editValue,
      newItem,
      edit,
      remove,
      updateChecked,
      valueInvalid,
      ...initInfo
    }
  }
}
