
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import UIInit from '@/components/Common'
import StyleFormValueList from '@/components/sidebar/style/ValueList.vue'
import { useStore } from 'vuex'

export default {
  name: 'StyleSelect',
  components: { StyleFormValueList },
  setup (props: any, context: any) {
    const initInfo = UIInit()
    const { t } = useI18n()
    const store = useStore()

    const isMultiple = computed({
      get () {
        return initInfo.getMeta('multiple', 'custom') || false
      },
      set (v) {
        initInfo.setMeta('multiple', v, 'custom')
      }
    })
    const taggable = computed({
      get () {
        return initInfo.getMeta('taggable', 'custom') || false
      },
      set (v) {
        initInfo.setMeta('taggable', v, 'custom')
      }
    })
    const isSearchable = computed({
      get () {
        return initInfo.getMeta('searchable', 'custom') || false
      },
      set (v) {
        initInfo.setMeta('searchable', v, 'custom')
      }
    })
    const size = computed({
      get () {
        return initInfo.getMeta('size', 'custom') || 0
      },
      set (v) {
        initInfo.setMeta('size', v, 'custom')
      }
    })
    const isMobile = computed(() => store.state.design?.endKind === 'mobile')
    return {
      t,
      isMultiple,
      taggable,
      isSearchable,
      size,
      ...initInfo,
      isMobile
    }
  }
}
