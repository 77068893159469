import { toDisplayString as _toDisplayString, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = { class: "text-primary" }
const _hoisted_3 = {
  key: 0,
  class: "btn-group btn-group-sm mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("button", {
      class: "btn btn-light btn-sm",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.openIconDialog && $setup.openIconDialog(...args)))
    }, [
      _createVNode("span", _hoisted_2, _toDisplayString($setup.icon), 1),
      ($setup.icon)
        ? (_openBlock(), _createBlock("span", {
            key: 0,
            class: "iconfont icon-remove text-danger ms-3",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($setup.clearIcon && $setup.clearIcon(...args)), ["stop","prevent"]))
          }))
        : _createCommentVNode("", true),
      (!$setup.icon)
        ? (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString($setup.t('common.chooseIcon')), 1)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    (!$props.hidePosition)
      ? (_openBlock(), _createBlock("div", _hoisted_3, [
          _createVNode("button", {
            type: "button",
            class: {'btn btn-outline-primary': true, 'active': $setup.iconPosition==='left'},
            onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.iconPosition='left'))
          }, _toDisplayString($setup.t('common.left')), 3),
          _createVNode("button", {
            type: "button",
            class: {'btn btn-outline-primary': true, 'active': $setup.iconPosition==='right'},
            onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.iconPosition='right'))
          }, _toDisplayString($setup.t('common.right')), 3),
          _createVNode("button", {
            type: "button",
            class: {'btn btn-outline-primary': true, 'active': $setup.iconPosition==='top'},
            onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.iconPosition='top'))
          }, _toDisplayString($setup.t('common.top')), 3),
          _createVNode("button", {
            type: "button",
            class: {'btn btn-outline-primary': true, 'active': $setup.iconPosition==='bottom'},
            onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.iconPosition='bottom'))
          }, _toDisplayString($setup.t('common.bottom')), 3)
        ]))
      : _createCommentVNode("", true)
  ]))
}