
import initUI from '@/components/Common'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import ColorPicker from '@/components/common/ColorPicker.vue'
import Upload from '@/components/common/Upload.vue'

export default {
  name: 'StyleTable',
  components: { Upload, ColorPicker },
  setup (props: any, context: any) {
    const info = initUI()
    const { t } = useI18n()
    const headless = computed({
      get: () => {
        return info.getMeta('headless', 'custom') || false
      },
      set: (v) => {
        info.setMeta('headless', v, 'custom')
      }
    })
    const stripedRow = computed({
      get: () => {
        return info.getMeta('stripedRow', 'custom')
      },
      set: (v) => {
        info.setMeta('stripedRow', v, 'custom')
      }
    })
    const hoverableRow = computed({
      get: () => {
        return info.getMeta('hoverableRow', 'custom')
      },
      set: (v) => {
        info.setMeta('hoverableRow', v, 'custom')
      }
    })

    const grid = computed({
      get () {
        return info.getMeta('grid', 'custom') || ''
      },
      set (v) {
        info.setMeta('grid', v, 'custom')
      }
    })
    const currExcelFile = computed({
      get () {
        const files = info.getMeta('datasource', 'files') || []
        return { id: files[0]?.id, name: files[0]?.name }
      },
      set (v: any) {
        info.setMeta('datasource', [{ id: v.id, name: v.name }], 'files')
      }
    })
    const footless = computed({
      get: () => {
        return info.getMeta('footless', 'custom') || false
      },
      set: (v) => {
        info.setMeta('footless', v, 'custom')
      }
    })

    const small = computed({
      get: () => {
        return info.getMeta('small', 'custom') || false
      },
      set: (v) => {
        info.setMeta('small', v, 'custom')
      }
    })
    const horizontalAlignment = computed({
      get: () => {
        return info.getMeta('textAlignment', 'css') || ''
      },
      set: (v) => {
        info.setMeta('textAlignment', v, 'css')
      }
    })
    const verticalAlignment = computed({
      get: () => {
        return info.getMeta('verticalAlignment', 'css') || ''
      },
      set: (v) => {
        info.setMeta('verticalAlignment', v, 'css')
      }
    })
    const headerCss = computed({
      get () {
        return info.getMeta('header', 'css')
      },
      set (v) {
        info.setMeta('header', v, 'css')
      }
    })
    const headerColor = computed({
      get () {
        return info.getMeta('header', 'custom')
      },
      set (v) {
        info.setMeta('header', v, 'custom')
      }
    })
    const footerCss = computed({
      get () {
        return info.getMeta('footer', 'css')
      },
      set (v) {
        info.setMeta('footer', v, 'css')
      }
    })
    const footerColor = computed({
      get () {
        return info.getMeta('footer', 'custom')
      },
      set (v) {
        info.setMeta('footer', v, 'custom')
      }
    })
    return {
      ...info,
      footless,
      headless,
      headerCss,
      footerCss,
      headerColor,
      footerColor,
      stripedRow,
      hoverableRow,
      currExcelFile,
      grid,
      t,
      small,
      verticalAlignment,
      horizontalAlignment
    }
  }
}
