
import initUI from '@/components/Common'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

export default {
  name: 'StyleCard',
  setup (props: any, context: any) {
    const info = initUI()
    const { t } = useI18n()
    const headless = computed({
      get: () => {
        return info.getMeta('headless', 'custom') || false
      },
      set: (v) => {
        info.setMeta('headless', v, 'custom')
      }
    })

    const footless = computed({
      get: () => {
        return info.getMeta('footless', 'custom') || false
      },
      set: (v) => {
        info.setMeta('footless', v, 'custom')
      }
    })
    return {
      ...info,
      footless,
      headless,
      t
    }
  }
}
