
import initUI from '@/components/Common'
import { useI18n } from 'vue-i18n'
import Position from '@/components/common/Position.vue'

export default {
  name: 'StyleModal',
  components: { Position },
  setup (props: any, context: any) {
    const info = initUI()
    const { t } = useI18n()
    const headless = info.computedWrap('headless', 'custom', false)
    const footless = info.computedWrap('footless', 'custom', false)
    const esc = info.computedWrap('esc', 'custom', true)
    const backdrop = info.computedWrap('backdrop', 'custom', 'yes')
    const position = info.computedWrap('position', 'custom', [])

    return {
      ...info,
      footless,
      headless,
      esc,
      backdrop,
      position,
      t
    }
  }
}
