
import initUI from '@/components/Common'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

export default {
  name: 'StyleProgress',
  setup (props: any, context: any) {
    const info = initUI()

    const min = computed({
      get () {
        return info.getMeta('min', 'custom') || 0
      },
      set (v) {
        info.setMeta('min', v, 'custom')
      }
    })
    const max = computed({
      get () {
        return info.getMeta('max', 'custom') || 100
      },
      set (v) {
        info.setMeta('max', v, 'custom')
      }
    })
    const value = computed({
      get () {
        return info.getMeta('value') || 50
      },
      set (v) {
        info.setMeta('value', v)
      }
    })
    const label = computed({
      get () {
        return info.getMeta('label', 'custom') || false
      },
      set (v) {
        info.setMeta('label', v, 'custom')
      }
    })
    const striped = computed({
      get () {
        return info.getMeta('striped', 'custom') || false
      },
      set (v) {
        info.setMeta('striped', v, 'custom')
      }
    })
    const animatedStrip = computed({
      get () {
        return info.getMeta('animatedStrip', 'custom') || false
      },
      set (v) {
        info.setMeta('animatedStrip', v, 'custom')
      }
    })
    const { t } = useI18n()
    return {
      ...info,
      t,
      min,
      max,
      value,
      label,
      striped,
      animatedStrip
    }
  }
}
