
import initUI from '@/components/Common'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { computed } from 'vue'
import ydhl from '@/lib/ydhl'

export default {
  name: 'StylePage',
  setup (props: any, context: any) {
    const info = initUI()
    const { t } = useI18n()
    const store = useStore()
    const currPage = computed(() => store.state.design.page)
    const project = computed(() => store.state.design.project)
    const myTitle = computed({
      get () {
        return currPage.value?.meta?.title
      },
      set (v) {
        if (!currPage.value) return
        info.setMeta('title', v)
      }
    })
    const myFile = computed({
      get () {
        return currPage.value?.meta?.custom?.file
      },
      set (v) {
        if (!currPage.value) return
        info.setMeta('file', v, 'custom')
      }
    })
    const myURL = computed({
      get () {
        return info.getMeta('url', 'custom')
      },
      set (v) {
        if (!currPage.value) return
        info.setMeta('url', v, 'custom')
      }
    })
    const isHomePage = computed({
      get () {
        return info.getMeta('isHomePage', 'custom')
      },
      set (v) {
        info.setMeta('isHomePage', v, 'custom')

        ydhl.post('api/update/homepage', { pageid: v ? currPage.value.meta?.id : '', projectid: project.value.id }, [], null, 'json')
      }
    })

    const isPopup = computed(() => store.state.design.page.pageType === 'popup')

    return {
      ...info,
      t,
      currPage,
      isHomePage,
      myTitle,
      myFile,
      isPopup,
      myURL
    }
  }
}
