
import initUI from '@/components/Common'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

export default {
  name: 'StyleFile',
  setup (props: any, context: any) {
    const info = initUI()
    const { t } = useI18n()
    const accept = computed({
      get () {
        return info.getMeta('accept', 'custom') || ''
      },
      set (v) {
        info.setMeta('accept', v, 'custom')
      }
    })
    const multiple = computed({
      get () {
        return info.getMeta('multiple', 'custom') || false
      },
      set (v) {
        info.setMeta('multiple', v, 'custom')
      }
    })

    return {
      ...info,
      multiple,
      accept,
      t
    }
  }
}
