
import DropdownValueList from '@/components/sidebar/style/DropdownValueList.vue'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import initUI from '@/components/Common'
import { useStore } from 'vuex'
import IconSetting from '@/components/sidebar/style/IconSetting.vue'

export default {
  name: 'StyleDropdown',
  components: { IconSetting, DropdownValueList },
  setup (props: any, context: any) {
    const { t } = useI18n()
    const store = useStore()
    const info = initUI()
    const title = computed({
      get: () => {
        return info.getMeta('title') || ''
      },
      set: (v) => {
        info.setMeta('title', v)
      }
    })
    const menuAlign = computed({
      get: () => {
        return info.getMeta('menuAlign', 'custom') || 'left'
      },
      set: (v) => {
        info.setMeta('menuAlign', v, 'custom')
      }
    })
    const direction = computed({
      get: () => {
        return info.getMeta('direction', 'custom') || 'dropdown'
      },
      set: (v) => {
        info.setMeta('direction', v, 'custom')
      }
    })
    const size = computed({
      get: () => {
        return info.getMeta('dropdownSizing', 'css') || ''
      },
      set: (v) => {
        info.setMeta('dropdownSizing', v, 'css')
      }
    })
    const isOutline = computed({
      get () {
        return info.getMeta('isOutline', 'custom') || false
      },
      set (v) {
        info.setMeta('isOutline', v, 'custom')
      }
    })
    const isSplitBtn = computed({
      get () {
        return info.getMeta('isSplit', 'custom') || false
      },
      set (v) {
        info.setMeta('isSplit', v, 'custom')
      }
    })
    const parentIsButtonGroupOrNav = computed(() => {
      const { parentConfig } = store.getters.getUIItemInPage(info.selectedUIItemId.value, info.selectedPageId.value)
      return parentConfig.type.toLowerCase() === 'buttongroup' || parentConfig.type.toLowerCase() === 'navbar' || parentConfig.type.toLowerCase() === 'nav'
    })

    return {
      ...info,
      t,
      menuAlign,
      direction,
      size,
      title,
      isOutline,
      parentIsButtonGroupOrNav,
      isSplitBtn
    }
  }
}
