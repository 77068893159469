
import initUI from '@/components/Common'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

export default {
  name: 'StylePagination',
  setup (props: any, context: any) {
    const info = initUI()
    const { t } = useI18n()
    const sizing = computed({
      get: () => {
        return info.getMeta('paginationSizing', 'css') || ''
      },
      set: (v) => {
        info.setMeta('paginationSizing', v, 'css')
      }
    })
    return {
      ...info,
      sizing,
      t
    }
  }
}
