
import initUI from '@/components/Common'
import { computed, toRaw } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
  name: 'StyleContainer',
  setup (props: any, context: any) {
    const info = initUI()
    const selectedUIItem = info.selectedUIItem
    const { t } = useI18n()
    const store = useStore()
    const typeCss = computed<string>({
      get () {
        return info.getMeta('container', 'css')
      },
      set (v) {
        info.setMeta('container', v, 'css')
      }
    })
    const endKind = computed<string>(() => store.state.design.endKind)
    const containerClass = computed<Array<string>>(() => {
      if (!selectedUIItem || endKind.value === 'mobile') return null
      const { parentConfig } = store.getters.getUIItemInPage(info.selectedUIItemId.value, info.selectedPageId.value)
      const rawParentConfig = toRaw(parentConfig)
      // console.log(rawParentConfig)
      // container => col => col的结构
      if (rawParentConfig && rawParentConfig.meta?.css?.container?.match(/row/)) {
        // console.log('col')
        return null
      }
      if (rawParentConfig && rawParentConfig.meta?.css?.container && rawParentConfig.meta?.css?.container !== '') {
        return info.cssMap.value.row
      }
      return info.cssMap.value.container
    })
    return {
      ...info,
      t,
      typeCss,
      containerClass
    }
  }
}
