
import initUI from '@/components/Common'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import ColorPicker from '@/components/common/ColorPicker.vue'

export default {
  name: 'StyleText',
  components: { ColorPicker },
  setup (props: any, context: any) {
    const info = initUI()
    // const selectedUIItem = info.selectedUIItem
    const { t } = useI18n()

    const textTypes = ref({ Heading: { H1: 'H1', H2: 'H2', H3: 'H3', H4: 'H4', H5: 'H5', H6: 'H6' }, Paragraph: { Paragraph: 'p', Normal: 'span' } })

    const currType = info.computedWrap('type', 'custom', 'span')
    const textAlign = info.computedWrap('align', 'custom', 'left')
    const bold = info.computedWrap('bold', 'custom', 'Normal')
    const italic = info.computedWrap('italic', 'custom', false)
    const underline = info.computedWrap('underline', 'custom', false)
    const linethrough = info.computedWrap('through', 'custom', false)
    const lineHeight = info.computedWrap('lineHeight', 'custom', '')
    const fontSize = info.computedWrap('font-size', 'style', '')
    const letterSpacing = info.computedWrap('letter-spacing', 'style', '')
    const value = info.computedWrap('value')

    const hshadow = info.computedWrap('textShadowH', 'custom', '')
    const vshadow = info.computedWrap('textShadowV', 'custom', '')
    const blur = info.computedWrap('textShadowBlur', 'custom', '')
    const color = info.computedWrap('textShadowColor', 'custom', '')
    watch([hshadow, vshadow, blur, color], (v) => {
      info.setMeta('text-shadow', `${hshadow.value} ${vshadow.value} ${blur.value} ${color.value}`, 'style')
    })
    return {
      ...info,
      t,
      textTypes,
      currType,
      textAlign,
      linethrough,
      italic,
      underline,
      bold,
      fontSize,
      letterSpacing,
      lineHeight,
      value,
      hshadow,
      vshadow,
      blur,
      color
    }
  }
}
