
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import UIInit from '@/components/Common'
import ColorPicker from '@/components/common/ColorPicker.vue'
export default {
  name: 'StyleInput',
  components: { ColorPicker },
  setup (props: any, context: any) {
    const initInfo = UIInit()
    const { t } = useI18n()

    const min = computed({
      get () {
        return initInfo.getMeta('min', 'custom') || 0
      },
      set (v) {
        initInfo.setMeta('min', v, 'custom')
      }
    })
    const max = computed({
      get () {
        return initInfo.getMeta('max', 'custom') || 0
      },
      set (v) {
        initInfo.setMeta('max', v, 'custom')
      }
    })
    const step = computed({
      get () {
        return initInfo.getMeta('step', 'custom') || 0
      },
      set (v) {
        initInfo.setMeta('step', v, 'custom')
      }
    })
    const foregroundColor = computed({
      get () {
        return initInfo.getMeta('color', 'custom')
      },
      set (v) {
        initInfo.setMeta('color', v, 'custom')
      }
    })
    const backgroundColor = computed({
      get () {
        return initInfo.getMeta('backgroundColor', 'custom')
      },
      set (v) {
        initInfo.setMeta('backgroundColor', v, 'custom')
      }
    })
    const themeCss = computed({
      get () {
        return initInfo.getMeta('theme', 'custom')
      },
      set (v) {
        initInfo.setMeta('theme', v, 'custom')
      }
    })
    return {
      t,
      min,
      max,
      step,
      ...initInfo,
      foregroundColor,
      backgroundColor,
      themeCss
    }
  }
}
