
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import initUI from '@/components/Common'
import IconSetting from '@/components/sidebar/style/IconSetting.vue'
export default {
  name: 'StyleInput',
  components: { IconSetting },
  setup (props: any, context: any) {
    const { t } = useI18n()
    const info = initUI()
    const types = ['Text', 'Color', 'Date', 'Email', 'Number', 'Password', 'URL']

    const currType = computed({
      get () {
        return info.getMeta('inputType', 'custom') || 'Text'
      },
      set (inputType) {
        info.setMeta('inputType', inputType, 'custom')
      }
    })
    const autocomplete = computed({
      get () {
        return info.getMeta('autocomplete', 'custom') || ''
      },
      set (v) {
        info.setMeta('autocomplete', v, 'custom')
      }
    })

    const clearButtonVisible = computed({
      get () {
        return info.getMeta('clearButtonVisible', 'custom') || 0
      },
      set (v) {
        info.setMeta('clearButtonVisible', v, 'custom')
      }
    })

    const wordCountVisible = computed({
      get () {
        return info.getMeta('wordCountVisible', 'custom') || 0
      },
      set (v) {
        info.setMeta('wordCountVisible', v, 'custom')
      }
    })
    const maxLength = computed({
      get () {
        return info.getMeta('maxLength', 'custom') || ''
      },
      set (v) {
        info.setMeta('maxLength', v, 'custom')
      }
    })
    return {
      t,
      currType,
      types,
      autocomplete,
      maxLength,
      wordCountVisible,
      clearButtonVisible
    }
  }
}
