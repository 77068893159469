
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import initUI from '@/components/Common'

export default {
  name: 'StyleTextarea',
  setup (props: any, context: any) {
    const { t } = useI18n()
    const info = initUI()

    const row = computed({
      get () {
        return info.getMeta('row', 'custom') || 0
      },
      set (v) {
        info.setMeta('row', v, 'custom')
      }
    })

    const autoRow = computed({
      get () {
        return info.getMeta('autoRow', 'custom') || 0
      },
      set (v) {
        info.setMeta('autoRow', v, 'custom')
      }
    })

    const clearButtonVisible = computed({
      get () {
        return info.getMeta('clearButtonVisible', 'custom') || 0
      },
      set (v) {
        info.setMeta('clearButtonVisible', v, 'custom')
      }
    })

    const wordCountVisible = computed({
      get () {
        return info.getMeta('wordCountVisible', 'custom') || 0
      },
      set (v) {
        info.setMeta('wordCountVisible', v, 'custom')
      }
    })
    const maxLength = computed({
      get () {
        return info.getMeta('maxLength', 'custom') || ''
      },
      set (v) {
        info.setMeta('maxLength', v, 'custom')
      }
    })
    return {
      t,
      row,
      autoRow,
      wordCountVisible,
      clearButtonVisible,
      maxLength
    }
  }
}
