
import initUI from '@/components/Common'
import { useI18n } from 'vue-i18n'
import IconSetting from '@/components/sidebar/style/IconSetting.vue'
import { computed } from 'vue'

export default {
  name: 'StyleButton',
  components: { IconSetting },
  setup (props: any, context: any) {
    const info = initUI()
    const { t } = useI18n()
    const fontSize = computed({
      get () {
        return info.getMeta('font-size', 'style') || ''
      },
      set (v) {
        info.setMeta('font-size', v, 'style')
      }
    })
    return {
      ...info,
      fontSize,
      t
    }
  }
}
